<template>
  <u-sidebar collapsible>
    <template #header>
      <app-logo />
    </template>

    <u-sidebar-subtitle>
      Заявки
    </u-sidebar-subtitle>

    <router-link :to="{ name: 'home' }" custom v-slot="{ isActive, navigate }">
      <u-sidebar-item icon="mdi-food" :active="isActive" @click="navigate">
        Создание заявки
      </u-sidebar-item>
    </router-link>

    <u-sidebar-subtitle>
      Мониторинг
    </u-sidebar-subtitle>

    <router-link
      :to="{ name: 'monitoring' }"
      custom
      v-slot="{ isActive, navigate }"
    >
      <u-sidebar-item
        icon="mdi-thermometer-low"
        :active="isActive"
        @click="navigate"
      >
        Мониторинг
      </u-sidebar-item>
    </router-link>
  </u-sidebar>
</template>

<script>
import AppLogo from "@/app/shared/new-component/AppLogo";
export default {
  name: "AppSidebar",
  components: { AppLogo }
};
</script>

<style>
.unicorn-sidebar {
  z-index: 10 !important;
  background-color: white;
}
</style>
