<template>
  <div class="offline">
    <icon class="text-8xl text-red-500" icon="mdi-alert-outline"></icon>
    <h2 class="text-3xl mb-4 font-medium">Ошибка школьной сети</h2>
    <p class="text-sm text-gray-700">
      В процессе отправки заявки возникла непредвиденная ошибка сети.
    </p>
    <p class="text-sm text-gray-700 mb-2">
      <b class="text-red-500">
        Проверьте соединение своего устройства со школьной сетью.
      </b>
      Или попробуйте оформить заявку позже.
    </p>

    <small class="text-xs text-gray-500 mb-4">
      Если проблема не решилась, обратитесь к администратору сети.
    </small>

    <core-button @click="$emit('close')" type="primary">
      <icon class="mr-2" icon="mdi-arrow-left"></icon>
      Вернуться назад
    </core-button>

    <small class="fixed bottom-4 text-sm mt-4 text-pink-600">
      Данное сообщение автоматически пропадет через 20 секунд
    </small>
  </div>
</template>

<script>
import CoreButton from "@/app/shared/core/CoreButton";

export default {
  name: "Offline",

  components: { CoreButton },

  data() {
    return {
      timeout: 0
    };
  },

  mounted() {
    document.body.style.overflow = "hidden";
    this.timeout = setTimeout(() => {
      this.$emit("close");
    }, 20 * 1000);
  },

  beforeDestroy() {
    document.body.style.removeProperty("overflow");
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped>
.offline {
  @apply flex
    inset-0
    z-50
    bg-white
    fixed
    flex-col
    p-16
    items-center
    justify-center
    bg-opacity-90;
}
</style>
