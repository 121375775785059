<template>
  <div class="flex">
    <img class="mx-auto h-6" src="../assets/images/mip.svg" />
  </div>
</template>

<script>
export default {
  name: "MipLogo"
};
</script>
