<template lang="pug">
div
  .flex.items-center.justify-between.p-4.border-b
    h3.text-xl.text-gray-900 Укажите {{ type === 'double' ? 'диапазон дат' : 'дату' }}
    .flex: slot

  .p-4.border-b.mb-4.grid.grid-cols-1.gap-2(
    v-if="type === 'double'",
    class="lg:flex"
  )
    .flex.items-center.justify-between(class="md:justify-start md:mr-4")
      p.mr-4.text-sm.font-medium Дата начала:
      //date-picker#begin(
      //  template="yyyy-MM-dd",
      //  :value="value.begin",
      //  min="",
      //  max="",
      //  @input="$emit('input', { ...value, begin: $event })"
      //)

    .flex.items-center.justify-between(class="md:justify-start")
      p.mr-4.text-sm.font-medium Дата окончания:
      //date-picker#end(
      //  template="yyyy-MM-dd",
      //  :value="value.end",
      //  min="",
      //  max="",
      //  @input="$emit('input', { ...value, end: $event })"
      //)

  .p-4.border-b.mb-4(v-else)
    .flex.items-center.justify-between(class="md:justify-start")
      p.mr-4.text-sm.font-medium Дата:
      u-date-picker(
        v-model="value"
        title-position="left"
        :min-date="minDate"
        :max-date="maxDate"
        @input="$emit('input', $event)"
      )
        template(v-slot="{ inputValue, inputEvents }")
          u-button(v-on="inputEvents") {{ inputValue }}
      //v-calendar#recovery(
      //  template="yyyy-MM-dd",
      //  :value="value",
      //  :min="minDate",
      //  :max="maxDate",
      //  @input="$emit('input', $event)"
      //)
</template>

<script>
export default {
  props: {
    value: { type: [Object, String], required: true },
    type: { type: String, default: "double" },
    minDate: { type: String, default: "" },
    maxDate: { type: String, default: "" }
  }
};
</script>
