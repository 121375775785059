<template lang="pug">
button.text-sm.font-medium.rounded.h-10.px-4.text-white.shadow-sm(
  @click="$emit('click')",
  :class="classes",
  class="focus:outline-none"
): slot
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "default",
      validator(value) {
        return [
          "default",
          "primary",
          "success",
          "danger",
          "warning",
          "info"
        ].includes(value);
      }
    }
  },

  computed: {
    classes() {
      if (this.type === "primary") return "bg-pink-600";
      if (this.type === "success") return "bg-green-400";
      if (this.type === "danger") return "bg-red-400";
      if (this.type === "warning") return "bg-orange-400";
      if (this.type === "info") return "bg-blue-400";

      return "bg-white text-gray-700 border";
    }
  }
};
</script>
