<template lang="pug">
CoreButton(@click="logout")
  Icon(class="sm:mr-2", icon="mdi-logout") 
  span.hidden(class="sm:inline-block") Выйти
</template>

<script>
import Api from "@/app/services/Api";

export default {
  methods: {
    async logout() {
      try {
        await Api.authorization.logout();
        sessionStorage.removeItem("profile");
      } catch {
        // PASS
      } finally {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>