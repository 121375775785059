<template lang="pug">
div(
  @click="$emit('change', !value)",
  style="z-index: 0"
  :class="['relative flex items-center w-10 h-5 sm:w-14 sm:h-7 rounded-full bg-gray-200', value && 'bg-green-400 text-green-600', disabled && 'bg-red-400 text-red-500 cursor-not-allowed']"
)
  div(
    :class="['flex items-center justify-center w-4 h-4 sm:w-6 sm:h-6 ml-px transform translate-x-0 rounded-full border bg-white', (value || disabled) && 'translate-x-5 sm:translate-x-7']"
  )
    Icon(v-if="value || disabled", icon="mdi-check")
</template>

<script>
export default {
  model: {
    event: "change"
  },

  props: {
    value: Boolean,
    disabled: Boolean
  }
};
</script>
