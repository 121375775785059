<template>
  <div class="app-logo" v-bind="$attrs" :class="{ invert }">
    <span class="app-logo__img">
      <img
        class="w-8 h-8 m-auto"
        src="../../shared/assets/images/logo.svg"
        alt="я.учитель"
      />
    </span>

    <div class="app-logo__text">
      <span class="app-logo__title">
        я.учитель
      </span>
      <span class="app-logo__desc">
        Личный кабинет
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLogo",
  props: {
    invert: Boolean
  }
};
</script>

<style scoped>
.app-logo {
  @apply flex items-center;
}

.app-logo__img {
  min-width: 2.5rem;
  min-height: 2.5rem;
  @apply inline-flex w-10 h-10 rounded-md mr-4;
  @apply bg-gray-100;
}

.app-logo__text {
  @apply flex flex-col;
}

.app-logo__title {
  @apply leading-none mb-1 text-xl font-bold;
  @apply text-gray-700;
}

.app-logo__desc {
  @apply leading-none truncate text-xs font-medium;
  @apply text-gray-400;
}

.app-logo.invert .app-logo__img {
  @apply bg-purple-500;
}

.app-logo.invert .app-logo__title {
  @apply text-white;
}

.app-logo.invert .app-logo__desc {
  @apply text-purple-200;
}
</style>
