<template>
  <u-m-bottom-nav>
    <router-link :to="{ name: 'home' }" custom v-slot="{ isActive, navigate }">
      <u-m-bottom-nav-item icon="mdi-food" :active="isActive" @click="navigate">
        Заявки
      </u-m-bottom-nav-item>
    </router-link>

    <router-link
      :to="{ name: 'monitoring' }"
      custom
      v-slot="{ isActive, navigate }"
    >
      <u-m-bottom-nav-item
        icon="mdi-thermometer-low"
        :active="isActive"
        @click="navigate"
      >
        Мониторинг
      </u-m-bottom-nav-item>
    </router-link>
  </u-m-bottom-nav>
</template>

<script>
export default {
  name: "MAppNavigation"
};
</script>
