<template>
  <u-menu right width="300">
    <template #activator="{toggle}">
      <u-button icon @click="toggle" text variant="secondary">
        <u-icon icon="mdi-qrcode" />
      </u-button>
    </template>

    <u-card>
      <u-card-title>
        Внешняя авторизация
      </u-card-title>

      <u-card-body v-if="error">
        <u-alert variant="danger">
          <template #title>
            Ошибка!
          </template>
          Невозможно загрузить токен атворизации. Попробуйте позднее

          <template #actions>
            <u-button @click="loadToken" text variant="danger">
              Попробовать снова
            </u-button>
          </template>
        </u-alert>
      </u-card-body>

      <u-card-body v-else>
        <p class="text-sm mb-2 font-medium text-black">
          Код доступа
        </p>
        <div class="flex">
          <div
            class="h-10 px-2 tracking-widest font-bold flex items-center uppercase justify-center text-2xl flex-1 bg-gray-100 rounded-lg"
          >
            {{ token }}
          </div>

          <div
            class="h-10 w-20 font-bold flex items-center justify-center bg-white rounded-lg"
          >
            <u-icon v-if="loading" icon="mdi-loading mdi-spin" />
            <span v-else> {{ timeout }} c.</span>
          </div>

          <u-button @click="loadToken" icon :disabled="timeout > 20">
            <u-icon icon="mdi-refresh" />
          </u-button>
        </div>

        <u-progressbar class="mt-4" :max-value="60" :value="timeout" />

        <p class="mt-4 text-xs text-gray-400">
          Отсканируйте QR-код для открытия личного кабинета на вашем устройстве
        </p>

        <qr-code class="mt-4 mx-auto" :value="url" />
      </u-card-body>

      <u-divider />

      <u-card-actions>
        <div>
          <p class="text-xs uppercase text-gray-400">
            Внешний личный кабинет
          </p>
          <p class="text-purple-500 underline font-medium">
            t.edu28.ru
          </p>
        </div>
      </u-card-actions>
    </u-card>
  </u-menu>
</template>

<script>
import Api from "@/app/services/Api";
import QrCode from "@/app/shared/new-component/QrCode";

export default {
  name: "QrCodeAuth",
  components: { QrCode },
  data() {
    return {
      token: "",
      timeout: 0,
      canLoad: true,
      loading: false,
      error: false,
      timer: 0
    };
  },

  computed: {
    url() {
      return `https://t.edu28.ru/#/login?token=${this.token}`;
    }
  },

  watch: {
    timeout: {
      handler() {
        if (this.timeout === 0) {
          // this.canLoad = true;
          this.loadToken();
        }
      },
      immediate: true
    }
  },

  beforeDestroy() {
    this.clearTimer();
  },

  methods: {
    async loadToken() {
      try {
        this.loading = true;
        this.error = false;

        const response = await Api.getToken();
        this.clearTimer();

        this.token = response.data.value;
        this.timeout = Math.floor(response.data.timeout / 1000);

        this.setTimer();
      } catch {
        this.error = true;
      } finally {
        this.canLoad = false;
        this.loading = false;
      }
    },

    clearTimer() {
      clearInterval(this.timer);
    },
    setTimer() {
      this.clearTimer();

      this.timer = setInterval(() => {
        this.timeout--;
      }, 1000);
    }
  }
};
</script>
