<template lang="pug">
.mb-4
  RequestStats.fixed.left-0.right-0.bottom-14(
    @send="closeRequest",
    :status="status",
    :food-sets="foodSets",
    :model="model"
    style="z-index: 3",
    class="md:sticky md:top-0"
  )

  .mt-4
    u-hide-at(breakpoint="md")
      div.-mt-8.mb-40
        u-collapse.my-8(v-for="(learner, index) in learners")
          template(#header)
            .flex.items-center.justify-between
              div
                p.text-lg.font-medium {{ learner.surname }} {{ learner.name }}
                p.text-xs.text-gray-400.font-medium(v-if="learner.socialGroups.length")
                  | Соц.: {{ createSocialGroupsNames(learner.socialGroups) }}
              span.ml-1.text-purple-600.font-medium {{ learner.balance | currency }}
          template(#default)
            template(v-if="learner.stamps.length")
              label.text-sm.text-gray-500 Талоны:
              table(class="w-full mb-2")
                tr(v-for="stamp in learner.stamps" :key="stamp.id")
                  td(v-if="stamp.selected"): icon(class="mr-2" icon="mdi-check")
                  td {{ stamp.name }}
                  td(class="text-right") {{ stamp.price | currency }}
              u-switch(
                v-if="!stampsRequested(learner)"
                @change="toggleStamps(learner)",
                :modelValue="stampsSelected(learner)",
                :variant="'success'",
                :icon="'mdi-check'",
                label="Использовать талоны"
                class="mr-2"
                style="z-index: 1",
              )
            u-switch(
              v-for="fs in foodSets", :key="fs.id"
              style="z-index: 1"
              class="mr-2"
              @change="toggleFoodSets(learner, fs)"
              :modelValue="foodSetSelected(learner, fs) || foodSetRequested(learner, fs) || !fitsLimit(learner, fs)",
              :variant="foodSetSelected(learner, fs) ? 'success' : foodSetRequested(learner, fs) ? 'danger' : 'warning'",
              :icon="foodSetSelected(learner, fs) ? 'mdi-check' : foodSetRequested(learner, fs) ? 'mdi-lock' : 'mdi-exclamation-thick'",
              :title="foodSetRequested(learner, fs) && 'Уже заказан' || !fitsLimit(learner, fs) && 'Недостаточно средств'"
              :label="fs.name"
            )
            u-button.w-full.mt-4(
              v-if="!learner.stamps.length",
              :text="cartEmpty(learner)"
              @click="openCart(learner)",
              variant="info"
              block
            ) {{ cartEmpty(learner) ? 'Свой заказ' : 'Изменить свой заказ' }}



    u-show-at(breakpoint="md")
      u-simple-table
        tbody
          template(v-for="(learner, index) in learners")
            tr(
              v-if="index % 5 === 0"
              :key="`header_${learner.id}`"
            )
              th(isFirst) #
              th ФИО, баланс
              th(v-if="hasStamps") Талоны
              th(v-for="fs in foodSets", :key="fs.id") {{ fs.name }}
              th(v-if="hasFreeOrders") Свой заказ
            tr(:key="learner.id")
              td {{ index + 1 }}
              td
                p.text-lg.font-medium {{ learner.surname }} {{ learner.name }}
                p.text-xs.text-gray-400(v-if="learner.socialGroups.length")
                  | Соц.: {{ learner.socialGroups.map((g) => g.name).join(', ') }}
                p.text-sm.text-gray-500
                  | Доступно:
                  span.ml-1.text-purple-600.font-medium {{ learner.balance | currency }}
              td(v-if="hasStamps")
                u-menu(
                  v-if="learner.stamps.length"
                  :ref="`stamps_menu_${learner.id}`" 
                  width="360"
                  right
                )
                  template(#activator="{ toggle }")
                    div(
                      @mouseover="setStampsMenuState(`stamps_menu_${learner.id}`, true)"
                      @mouseout="setStampsMenuState(`stamps_menu_${learner.id}`, false)"
                    )
                      u-switch(
                        :modelValue="stampsRequested(learner) || stampsSelected(learner)",
                        :variant="stampsRequested(learner) ? 'danger' : 'success'",
                        :icon="stampsRequested(learner) ? 'mdi-lock' : 'mdi-check'",
                        @change="toggleStamps(learner)"
                      )
                  u-card(class="px-1 mr-24 -mt-12")
                    u-list
                      span(
                        class="mb-2 text-gray-400 text-xs font-medium uppercase"
                      ) Талоны: {{ learner.surname }} {{ learner.name }}
                      table(class="w-full")
                        tr(v-for="stamp in learner.stamps" :key="stamp.id")
                          td(v-if="stamp.selected"): icon(class="mr-2" icon="mdi-check")
                          td {{ stamp.name }}
                          td {{ stamp.price | currency }}
                span.text-sm.text-gray-500(v-else) Нет талонов
              td(v-for="fs in foodSets", :key="fs.id")
                u-switch(
                  :modelValue="foodSetSelected(learner, fs) || foodSetRequested(learner, fs) || !fitsLimit(learner, fs)",
                  :variant="foodSetSelected(learner, fs) ? 'success' : foodSetRequested(learner, fs) ? 'danger' : 'warning'",
                  :icon="foodSetSelected(learner, fs) ? 'mdi-check' : foodSetRequested(learner, fs) ? 'mdi-lock' : 'mdi-exclamation-thick'",
                  :title="foodSetRequested(learner, fs) && 'Уже заказан' || !fitsLimit(learner, fs) && 'Недостаточно средств'"
                  @change="toggleFoodSets(learner, fs)"
                )
              td(v-if="hasFreeOrders")
                u-button(
                  v-if="!learner.stamps.length"
                  @click="openCart(learner)",
                  variant="info"
                  :text="cartEmpty(learner)"
                ) {{ cartEmpty(learner) ? 'Создать' : 'Изменить' }}
                span.text-sm.text-gray-500(v-else) Недоступно
          tr(class="bg-gray-100 md:sticky md:bottom-0")
            td(isFirst, :colspan="2") Итого
            td.text-pink-700(v-if="hasStamps") {{ countStamps() }}
            td.text-pink-700(v-for="fs in foodSets", :key="fs.id") {{ countFoodSets(fs.id) }}
            td.text-pink-700(v-if="hasFreeOrders") {{ countFreeOrders() }}
  Menu(
    v-if="learner",
    :learner="learner"
    :cart="model[learner.id].cart"
    :setCart="setCart"
    :fitsLimit="fitsLimit"
    :addItem="addItem"
    :removeItem="removeItem"
    :learnerTotal="learnerTotal"
    @close="closeCart",
  )
</template>

<script>
import Vue from "vue";
import {
  filter,
  find,
  flatMap,
  keys,
  map,
  orderBy,
  reduce,
  unionBy,
  values,
  xorBy
} from "lodash";

export default {
  props: {
    learners: { type: Array, default: () => [] },
    date: { type: String, default: null }
  },

  data() {
    return {
      model: {},
      learner: undefined,
      status: null
    };
  },

  watch: {
    model: {
      handler() {
        this.filterModel();
      },
      deep: true
    },
    learner() {
      this.filterModel();
    }
  },

  computed: {
    hasStamps() {
      return flatMap(this.learners, "stamps").length > 0;
    },

    hasFreeOrders() {
      return !!find(this.learners, learner => learner.stamps.length === 0);
    },

    foodSets() {
      const filtered = filter(
        flatMap(this.learners, "sets"),
        set => !set.archival && !set.selected
      );
      return orderBy(unionBy(filtered, "id"), "price", "asc");
    }
  },

  methods: {
    closeRequest() {
      const { classId } = this.$route.params;
      const body = values(this.model).map(v => ({
        userId: v.userId,
        setsId: map(v.sets, "id"),
        items: values(v.cart)
      }));
      this.$emit("close-request", {
        classId,
        body,
        stats: map(this.foodSets, fs => ({
          ...fs,
          count: this.countFoodSets(fs.id)
        })).concat(
          {
            id: -1,
            name: "По талонам",
            count: this.countStamps()
          },
          {
            id: -2,
            name: "Свободный заказ",
            count: this.countFreeOrders()
          }
        )
      });
      this.model = {};
    },

    filterModel() {
      Object.entries(this.model).forEach(([learnerId, learner]) => {
        if (
          !(
            learner.stamps ||
            learner.sets.length ||
            Object.values(learner.cart).length ||
            this.learner?.id === learner.userId
          )
        ) {
          Vue.delete(this.model, learnerId);
        }
      });
    },

    openCart(learner) {
      const model = this.getModelValue(learner);
      Vue.set(this.model, learner.id, model);
      this.learner = learner;
    },

    closeCart() {
      this.learner = undefined;
    },

    setCart(learner, cart) {
      const model = this.getModelValue(learner);
      model.cart = cart;
      Vue.set(this.model, learner.id, JSON.parse(JSON.stringify(model)));
    },

    addItem(learner, item) {
      if (!this.fitsLimit(learner, item)) return;
      const model = this.getModelValue(learner);
      model.cart[item.id] = model.cart[item.id] || { ...item };
      model.cart[item.id].count = (model.cart[item.id].count || 0) + 1;
      Vue.set(this.model, learner.id, JSON.parse(JSON.stringify(model)));
    },

    removeItem(learner, item) {
      const model = this.getModelValue(learner);
      model.cart[item.id].count--;
      if (model.cart[item.id].count === 0) {
        delete model.cart[item.id];
      }
      Vue.set(this.model, learner.id, JSON.parse(JSON.stringify(model)));
    },

    cartEmpty(learner) {
      return keys(this.model[learner.id]?.cart || {}).length === 0;
    },

    price(item) {
      return (item.count || 1) * item.price;
    },

    learnerTotal(learner) {
      const { cart, sets } = this.getModelValue(learner);
      const items = values(cart).concat(sets);
      return reduce(items, (sum, item) => sum + this.price(item), 0);
    },

    fitsLimit(learner, item) {
      return learner.balance >= this.learnerTotal(learner) + this.price(item);
    },

    stampsRequested(learner) {
      return filter(learner.stamps, { selected: false }).length === 0;
    },

    foodSetRequested(learner, fs) {
      return !!find(learner.sets, { id: fs.id, selected: true });
    },

    foodSetSelected(learner, foodSet) {
      return !!find(this.model[learner.id]?.sets || [], { id: foodSet.id });
    },

    toggleFoodSets(learner, ...foodSets) {
      const filtered = filter(foodSets, fs => {
        const selected = this.foodSetSelected(learner, fs);
        const fitsLimit = this.fitsLimit(learner, fs);
        const requested = this.foodSetRequested(learner, fs);
        return selected || (fitsLimit && !requested);
      });
      const model = this.getModelValue(learner);
      model.sets = xorBy(model.sets, filtered, "id");
      Vue.set(this.model, learner.id, model);
    },

    stampsSelected(learner) {
      const model = this.getModelValue(learner);
      return model.stamps;
    },

    toggleStamps(learner) {
      if (this.stampsRequested(learner)) return;
      const model = this.getModelValue(learner);
      model.stamps = !model.stamps;
      Vue.set(this.model, learner.id, model);
    },

    countFoodSets(id) {
      const sets = flatMap(this.model, learner => learner.sets);
      const filtered = filter(sets, { id });
      return filtered.length;
    },

    countStamps() {
      return filter(this.model, "stamps").length;
    },

    countFreeOrders() {
      return filter(this.model, learner => keys(learner.cart).length > 0)
        .length;
    },

    getModelValue(learner) {
      return (
        this.model[learner.id] || {
          userId: learner.id,
          stamps: false,
          sets: [],
          cart: {}
        }
      );
    },

    setStampsMenuState(ref, state) {
      this.$refs[ref][0].active = state;
    }
  }
};
</script>
