import Axios from "axios";
import router from "@/app/router";

const instance = Axios.create({
  baseURL: `${location.origin}/api/v1/teacher`,
  timeout: 12000
});

const auth = Axios.create({
  baseURL: `${location.origin}/api/v1`,
  timeout: 12000
});

instance.interceptors.response.use(null, err => {
  const { status, config } = (err && err.response) || {};
  if (status === 401 && !config.url.endsWith("/login")) {
    router.push({ name: "login" });
  }

  throw err;
});

const authorization = {
  login: (login, password) =>
    auth.post(
      `/auth?login=${login}&pwd=${password}`,
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    ),
  loginWithToken: token =>
    auth.post(
      `/auth?token=${token}`,
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      }
    ),
  logout: () => auth.get(`/logout`),
  currentUser: () => auth.get(`/current-user`)
};

const classes = {
  getClasses: () => instance.get("/class"),
  getClassLearners: (classId, date) =>
    instance
      .get(`/class/${classId}/learners${date ? `?date=${date}` : ""}`)
      .then(r => {
        r.data.forEach(
          learner => (learner.socialGroups = learner.socialGroups || [])
        );
        return r;
      })
};

const menu = {
  getFoodsets: () => instance.get("/foodSet"),
  getMenu: () => instance.get("/menu")
};

const requests = {
  closeRequest: (classId, body, date) =>
    instance.post(
      `/class/${classId}/request/issue${date ? `?date=${date}` : ""}`,
      body
    )
};

const socialGroups = {
  get: () => instance.get("/social_group")
};

const other = {
  time: () => instance.get("/time"),
  getToken: () => instance.get("/token")
};

const monitoring = {
  getClasses: () => instance.get(`/event/classes`),
  getLearners: (id, date) =>
    instance.get(`/event/class/${id}/learners?date=${date}`)
};

export default {
  ...other,
  authorization,
  classes,
  menu,
  requests,
  socialGroups,
  monitoring
};
