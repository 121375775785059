<template>
  <div class="inline">
    <u-hide-at breakpoint="md">
      <u-button @click="goToArchive" icon>
        <u-icon icon="mdi-archive" />
      </u-button>
    </u-hide-at>

    <u-show-at breakpoint="md">
      <u-button variant="secondary" text @click="goToArchive">
        Архив заявок
      </u-button>
    </u-show-at>
  </div>
</template>

<script>
export default {
  methods: {
    goToArchive() {
      const { classId } = this.$route.params;
      this.$router.push({ name: "archive", params: { classId } });
    }
  }
};
</script>
