import Vue from "vue";
import App from "./App.vue";

import router from "./app/router";
import store from "./app/store";
import UnicornUi from "unicorn-ui";

import "./app/shared/assets/styles/index.css";
import "@mdi/font/css/materialdesignicons.css";
import "unicorn-ui/dist/unicorn-ui.css";

import "./registerServiceWorker";
import "./registerComponents";
import "./registerFilters";

Vue.use(UnicornUi);

import VCalendar from "v-calendar";

Vue.use(VCalendar, {
  componentPrefix: "u"
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
