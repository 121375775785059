<template>
  <div>
    <u-menu v-if="classes.length > 3">
      <template #activator="{toggle}">
        <u-button class="w-36" @click="toggle">
          {{ selected || "Выберите класс" }}
        </u-button>
      </template>

      <u-card>
        <u-card-body>
          <router-link
            v-for="cl in classes"
            :key="cl.id"
            custom
            :to="{ name: 'request', params: { classId: cl.id } }"
            v-slot="{ navigate, isActive }"
          >
            <u-tab
              class="w-full my-1"
              @click="
                $router.push({ name: 'request', params: { classId: cl.id } }),
                  (selected = `${cl.number}${cl.liter} класс`)
              "
              :active="isActive"
            >
              {{ cl.number }}{{ cl.liter }} класс
            </u-tab>
          </router-link>
        </u-card-body>
      </u-card>
    </u-menu>

    <div v-else class="flex overflow-x-auto mr-4 flex-1">
      <router-link
        v-for="cl in classes"
        :key="cl.id"
        custom
        :to="{ name: 'request', params: { classId: cl.id } }"
        v-slot="{ navigate, isActive }"
      >
        <u-tab @click="navigate" :active="isActive">
          {{ cl.number }}{{ cl.liter }}
        </u-tab>
      </router-link>
    </div>
  </div>
</template>

<script>
import Api from "@/app/services/Api";

export default {
  data() {
    return {
      ok: true,
      loading: false,
      error: null,

      selected: "",

      classes: []
    };
  },

  async mounted() {
    await this.loadClasses();
  },

  methods: {
    async loadClasses() {
      try {
        this.loading = true;
        const { data: classes } = await Api.classes.getClasses();
        this.classes = classes;
        this.loading = false;
        this.ok = true;
      } catch (error) {
        this.ok = false;
        this.loading = false;
        this.error = error;
      }
    }
  }
};
</script>
