<template>
  <u-app id="app">
    <template v-if="!isLoginPage">
      <u-hide-at breakpoint="md">
        <m-app-statusbar />
      </u-hide-at>

      <u-hide-at breakpoint="md">
        <div>
          <!--          <m-app-header />-->
          <m-app-navigation />
        </div>
      </u-hide-at>

      <u-show-at breakpoint="md">
        <div>
          <app-sidebar />
        </div>
      </u-show-at>
    </template>

    <u-main v-if="!isLoginPage">
      <u-show-at breakpoint="md">
        <app-header />
      </u-show-at>

      <router-view />
    </u-main>

    <router-view v-else />
  </u-app>
</template>

<script>
import Api from "./app/services/Api";
import AppSidebar from "@/app/shared/new-component/AppSidebar";
// import MAppHeader from "@/app/shared/new-component/MAppHeader";
// import MAppNavigation from "@/app/shared/new-component/MAppNavigation";
import MAppStatusbar from "@/app/shared/new-component/MAppStatusbar";
import AppHeader from "@/app/shared/new-component/AppHeader";
import MAppNavigation from "@/app/shared/new-component/MAppNavigation";

export default {
  components: {
    MAppNavigation,
    AppHeader,
    MAppStatusbar,
    // MAppNavigation,
    // MAppHeader,
    AppSidebar
  },

  computed: {
    isLoginPage() {
      return this.$route.name === "login";
    }
  },

  async created() {
    const { data: profile } = await Api.authorization.currentUser();
    sessionStorage.setItem("profile", JSON.stringify(profile));
    if (this.$router.currentRoute.name !== "request") {
      await this.$router.push({ name: "request" });
    }
  }
};
</script>
