<template lang="pug">
div
  h1.unicorn-h1.mt-4 Создание заявки

  .flex.item-center.justify-between.my-8
    SelectClasses
    div(v-if="$route.params.classId")
      ArchiveButton.mr-4
      RecoveryButton

  .grid.grid-cols-1(class="md:mb-0")
    Request(
      v-if="$route.params.classId",
      v-show="ok && !loading",
      :learners="learners",
      @close-request="closeRequestConfirm"
    )

    CoreLoading(v-if="loading")

    u-alert(v-if="!loading && !$route.params.classId" variant="info" icon="mdi-information")
      template(#title) Как оформить заявку?
      | Для оформления заявки выберите соответсвующий класс из списка доступных выше.

    u-dialog(persistent width="360" v-model="confirm")
      template
        u-card
          u-card-title Отправка заявки
          u-card-body
            u-alert(variant="danger") Внимание! Это действие нельзя отменить после подтверждения
            p.mt-4.font-medium Вы отправляете заявку в столовую:
            table.mt-2
              tr(v-for="entry in waitingRequest.stats", :key="entry.id")
                td {{ entry.name }}
                td: span.ml-2.text-red-600 {{ entry.count }} шт.
          u-card-actions
            u-spacer
            u-button.mr-2(@click="confirm = false" variant="secondary" text) Отмена
            u-button(@click="closeRequest" variant="danger") Продолжить

  offline(v-if="offlineError", @close="offlineError = false")
</template>

<script>
import { sortBy } from "lodash";

import Api from "../services/Api";
import Offline from "@/app/shared/components/Offline";
import CoreLogo from "@/app/shared/core/CoreLogo";
import UserProfile from "@/app/shared/new-component/UserProfile";
import AppLogo from "@/app/shared/new-component/AppLogo";
import AppSidebar from "@/app/shared/new-component/AppSidebar";

export default {
  components: { AppSidebar, AppLogo, UserProfile, CoreLogo, Offline },
  data() {
    return {
      learners: [],
      request: {},
      socialGroups: [],

      offlineError: false,
      confirm: false,
      dialog: true,

      waitingRequest: { classId: null, body: null },

      ok: false,
      loading: false,
      error: false,

      autoUpdateId: null
    };
  },

  watch: {
    async $route() {
      const { classId } = this.$route.params;
      if (!classId) return;

      try {
        this.loading = true;
        await this.loadEntrants(classId);
        this.ok = true;
      } catch (error) {
        this.ok = false;
        this.error = error;
      } finally {
        this.loading = false;
      }
    }
  },

  async mounted() {
    const { classId } = this.$route.params;

    try {
      this.loading = true;
      await this.loadEntrants(classId);
      this.loading = false;
      this.ok = true;
    } catch (error) {
      this.ok = false;
      this.loading = false;
      this.error = error;
    }

    await this.startAutoUpdate();
  },

  beforeDestroy() {
    this.stopAutoUpdate();
  },

  methods: {
    async startAutoUpdate() {
      const { classId } = this.$route.params;
      await this.stopAutoUpdate();
      this.autoUpdateId = setInterval(() => {
        this.getServerTime(classId);
      }, 5000);
    },

    async stopAutoUpdate() {
      clearInterval(this.autoUpdateId);
    },

    async getServerTime() {
      await Api.time();
    },

    async loadEntrants(classId) {
      const { data: learners } = await Api.classes.getClassLearners(classId);
      this.learners = sortBy(learners, ["surname", "name"]);
    },

    async closeRequestConfirm(req) {
      this.confirm = true;
      this.waitingRequest = req;
    },

    async closeRequest() {
      const { classId, body } = this.waitingRequest;

      try {
        await Api.requests.closeRequest(classId, body);
        await this.loadEntrants(classId);
      } catch (error) {
        const { status } = (error && error.response) || { status: error };
        alert(this.getRequestCloseErrorDesc(status));
      } finally {
        this.confirm = false;
      }

      this.waitingRequest = { classId: null, body: null };
    },

    getRequestCloseErrorDesc(httpErrorCode) {
      switch (httpErrorCode) {
        case 402:
          return "Недостаточно средств. Попробуйте обновить сраницу (клавиша F5)";
        case 404:
          return "Заявка не найдена. Попробуйте перезагрузить страницу (клавиша F5)";
        case 422:
          return "Ошибка в 'Своем заказе'";
        case 409:
          return "Повторное оформление заявки";
        case 500:
          return "Ошибка сервера. Попробуйте позднее";
        case 502:
          return "Тех. работы на сервере. Попробуйте через 5 минут";
        default:
          this.offlineError = true;
          return "Неопознанная ошибка: " + httpErrorCode;
      }
    }
  }
};
</script>
