<template lang="pug">
.bg-black.bg-opacity-40.p-4.fixed.inset-0.flex.z-50(@click.self="$emit('close')")
  .bg-white.shadow-xl.rounded.max-w-md.w-full.m-auto
    .flex.p-4.items-center.justify-between.border-b
      h3.text-base.font-medium {{ title }}
      button.rounded.bg-gray-100.inline-flex.items-center.justify-center.w-8.h-8(
        @click="$emit('close')"
      ): Icon(
        icon="mdi-close"
      )

    .flex.flex-col.p-4: slot
    .flex.flex-row-reverse.p-4.border-t
      button.text-sm.rounded.bg-red-400.text-white.shadow-sm.px-2.h-8(
        @click="$emit('close'); $emit('confirm')"
      ) Продолжить

      button.border.text-sm.rounded.bg-white.shadow-sm.px-2.h-8.mr-4(
        @click="$emit('close'); $emit('cancel')"
      ) Отмена
</template>

<script>
export default {
  props: {
    title: String
  },

  mounted() {
    document.body.style.overflow = "hidden";
  },

  beforeDestroy() {
    document.body.style.removeProperty("overflow");
  }
};
</script>
