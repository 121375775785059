<template lang="pug">
.bg-black.bg-opacity-40.p-4.fixed.inset-0.flex.overflow-y-auto.z-50(
  @click.self="$emit('close')"
)
  .bg-white.shadow-xl.rounded.max-w-4xl.w-full.m-auto
    .flex.p-4.items-center.justify-between.border-b
      h3.text-base.font-medium Свой заказ
      button.rounded.bg-gray-100.inline-flex.items-center.justify-center.w-8.h-8(
        @click="$emit('close')"
      ): Icon(
        icon="mdi-close"
      )

    .flex.border-b.px-4.overflow-x-auto.min-w-0(v-if="menu.length")
      span.h-14.flex.items-center.mr-4.whitespace-nowrap.cursor-pointer.text-sm(
        v-for="(type, idx) in menu",
        :key="type.id",
        @click="changeType(idx)",
        class="hover:text-pink-600",
        :class="idx === currentTypeIndex && 'border-b-2 border-pink-900 text-pink-900'"
      ) {{ type.name }}

    .flex.flex-col.p-2.max-h-96.h-96.overflow-y-auto(
      class="sm:flex-row sm:h-80 sm:max-h-80 sm:overflow-y-hidden",
      v-if="menu.length"
    )
      .flex.px-2.flex-col.flex-auto.pb-4.mb-2.border-b(
        class="sm:w-1/2 sm:border-r sm:pr-4 sm:mr-2 sm:border-b-0 sm:overflow-y-auto"
      )
        h3.text-sm.mb-4.mt-2.text-gray-700.font-medium Список блюд в меню

        CoreTable(v-if="currentType.foods.length")
          CoreTableHead
            CoreTableCell(isFirst) #
            CoreTableCell Название
            CoreTableCell Вес
            CoreTableCell Цена

          CoreTableBody
            CoreTableRow.cursor-pointer(
              v-for="(food, idx) in currentType.foods",
              :key="food.id",
              :title="!fitsLimit(learner, food) && 'Недостаточно средств'"
              :class="{'opacity-50': !fitsLimit(learner, food)}"
              class="hover:bg-gray-100",
              @click="addItem(learner, Object.assign(food, { type: currentType.name || 'Прочее' }))"
            )
              CoreTableCell.text-xs(isFirst) {{ idx + 1 }}
              CoreTableCell.text-xs {{ food.name }}
              CoreTableCell.text-xs {{ food.weight }}
              CoreTableCell.text-xs {{ food.price | currency }}

        CoreInfo(v-else, type="info", icon="mdi-information-outline")
          p Нет позиций в выбранной категории

      .flex.flex-col.px-2(class="sm:w-1/2 sm:overflow-y-auto")
        .flex.flex-col.flex-auto
          h3.text-sm.mb-4.mt-2.text-gray-700.font-medium Выбранные позиции

          CoreTable
            CoreTableHead
              CoreTableCell(isFirst) #
              CoreTableCell Название
              CoreTableCell Цена
              CoreTableCell Сумма

            CoreTableBody
              CoreTableRow.cursor-pointer(
                v-for="(food, idx) in selected",
                :key="food.id",
                @click="removeItem(learner, food)"
              )
                CoreTableCell.text-xs(isFirst) {{ idx + 1 }}
                CoreTableCell.text-xs.truncate {{ food.name }}
                CoreTableCell.text-xs {{ food.price | currency }} x {{ food.count }}
                CoreTableCell.text-xs {{ food.price * food.count | currency }}
              CoreTableRow(isLast)
                CoreTableCell.text-xs(colspan="2") Итого
                CoreTableCell.text-xs x {{ totalCount }}
                CoreTableCell.text-sm.font-medium.text-pink-700 {{ totalSum | currency }}

        .flex.p-2.border-t.mt-4.pt-4.sticky.bottom-0.bg-white
          .text-xs.justify-between.w-full.grid.grid-cols-2.gap-2(class="sm:flex")
            p.text-gray-500
              | Доступно:
              span.ml-1.text-gray-700 {{ learner.balance | currency }}
            p.text-gray-500
              | Остаток:
              span.ml-1.text-red-500 {{ learner.balance - learnerTotal(learner) | currency }}
            p.text-gray-500
              | Итог:
              span.ml-1.text-pink-700 {{ totalSum | currency }}

    .flex.p-4.justify-center(v-else): CoreInfo(type="info", icon="mdi-hamburger")
      p Извините, меню на сегодня пустое :(

    .flex.p-4.border-t.justify-end
      button.inline-flex.items-center.text-xs.h-10.font-medium.border.shadow-sm.bg-white.rounded.px-4.mr-4(
        @click="setCart(learner, {})"
      ) Очистить
      button.inline-flex.items-center.text-xs.h-10.font-medium.bg-green-400.text-white.rounded.px-4(
        v-if="menu.length",
        @click="$emit('close')"
      ) Продолжить
</template>

<script>
import { sortBy, sum, values } from "lodash";
import Api from "@/app/services/Api";

export default {
  props: {
    learner: { type: Object, required: true },
    cart: { type: Object, required: true },
    learnerTotal: { type: Function, required: true },
    setCart: { type: Function, required: true },
    addItem: { type: Function, required: true },
    fitsLimit: { type: Function, required: true },
    removeItem: { type: Function, required: true }
  },

  data() {
    return {
      currentTypeIndex: 0,
      customSet: [],
      menu: []
    };
  },

  async mounted() {
    document.body.style.overflow = "hidden";
    await this.loadMenu();
  },

  beforeDestroy() {
    document.body.style.removeProperty("overflow");
  },

  computed: {
    selected() {
      return values(this.cart);
    },

    currentType() {
      return this.menu[this.currentTypeIndex] || { foods: [] };
    },

    totalCount() {
      return sum(this.selected.map(f => f.count));
    },

    totalSum() {
      return sum(this.selected.map(f => f.count * f.price));
    }
  },

  methods: {
    async loadMenu() {
      const { data: menu } = await Api.menu.getMenu();
      this.menu = sortBy(menu.types, ["priority"]).reverse();
    },

    removeFromSet(index) {
      this.customSet.splice(index, 1);
    },

    changeType(index) {
      this.currentTypeIndex = index;
    }
  }
};
</script>
