<template>
  <u-m-app-header>
    <h1 class="unicorn-h1 text-white">{{ $route.meta }}</h1>
  </u-m-app-header>
</template>

<script>
export default {
  name: "MAppHeader"
};
</script>
