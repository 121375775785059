<template lang="pug">
.grid.bg-white.py-4.border-t.border-b.border-gray-100.grid-cols-1.gap-2(class="px-4 md:px-0 md:grid-cols-2 md:items-center")
  .flex.flex-col.mr-4.pr-4
    p.text-sm.text-gray-400 Статус заявки
    p.text-lg.font-medium(
      :class="[status === 'empty' && 'text-gray-700', status === 'success' && 'text-green-500', status === 'waiting' && 'text-blue-500', status === 'waiting_recovery' && 'text-red-500']"
    ) {{ statuses[status] }}

  u-button(variant="success" block @click="$emit('send')" :disabled="sendDisabled")
    | Отправить заявку
    u-icon(right icon="mdi-arrow-right")
</template>

<script>
export default {
  props: {
    status: String,
    foodSets: { type: Array, default: () => [] },
    positionsCount: { type: Object, default: () => {} },
    model: Object
  },

  data() {
    return {
      statuses: {
        empty: "Ожидает создания",
        success: "Полностью обработана",
        waiting: "Ожидает обработки в столовой",
        waiting_recovery: "Ожидает восстановления"
      }
    };
  },

  computed: {
    sendDisabled() {
      return !Object.values(this.model).length;
    }
  }
};
</script>
