<template lang="pug">
.field.relative.text-sm
  label.text-gray-500(v-if="label", :for="id") {{ label }}
  .flex.items-center.relative.border.rounded.text-gray-700(
    class="focus-within:border-pink-500"
  )
    input.w-full.h-10.rounded.outline-none.px-4(
      :id="id",
      :type="type",
      :placeholder="placeholder",
      :disabled="disabled",
      :value="value",
      :required="required",
      :class="icon && 'pl-12'",
      @input="$emit('input', $event.target.value)"
    )

    .absolute.text-gray-500.ml-1.bottom-0.mb-1.h-8.w-8.bg-gray-100.border.rounded.items-center.justify-center.inline-flex(
      v-if="icon"
    ): Icon(
      :icon="icon"
    )
</template>

<script>
export default {
  props: {
    value: [String, Number],
    id: { type: String, required: true },
    disabled: { type: [String, Boolean] },
    required: { type: [String, Boolean] },
    label: { type: String },
    placeholder: String,
    icon: String,
    type: { type: String, default: "text" },
  },
};
</script>