<template>
  <u-m-appbar class="-mb-8">
    <template #left>
      <app-logo v-if="isHome" />
      <u-button v-else @click="$router.back()" icon>
        <u-icon icon="mdi-arrow-left" />
      </u-button>
    </template>

    <template #right>
      <u-menu right v-if="profile">
        <template #activator="{toggle}">
          <u-button @click="toggle" icon>
            <u-icon icon="mdi-account" />
          </u-button>
        </template>

        <u-card>
          <u-list>
            <p
              class="px-4 mt-2 mb-2 text-gray-400 text-xs font-medium uppercase"
            >
              {{ profile.surname }} {{ profile.name }}
            </p>

            <logout-dialog />
          </u-list>
        </u-card>
      </u-menu>
    </template>

    <template v-if="!isHome" #default>
      {{ $route.meta }}
    </template>
  </u-m-appbar>
</template>

<script>
import AppLogo from "./AppLogo.vue";

export default {
  name: "MAppStatusbar",
  components: { AppLogo },

  data() {
    return {
      profile: JSON.parse(sessionStorage.getItem("profile"))
    };
  },

  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  }
};
</script>
