<template lang="pug">
.grid.grid-cols-3.gap-2
  .col-span-3
    p.text-lg.font-medium {{ learner.surname }} {{ learner.name }}
  template(v-if="empty")
    .col-span-3(class="md:col-span-1")
      label.text-sm.text-gray-500 Заявка отсутствует

  template(v-if="sets.length")
    .col-span-3(class="md:col-span-1")
      label.text-sm.text-gray-500 Комплексы
      table.w-full
        tbody
          tr(v-for="item in sets")
            td {{ item.name }}
            td {{ item.price | currency }}

  template(v-if="stamps.length")
    .col-span-3(class="md:col-span-1")
      label.text-sm.text-gray-500 Талоны
      table.w-full
        tbody
          tr(v-for="item in stamps")
            td {{ item.name }}
            td {{ item.price | currency }}

  template(v-if="operations.length")
    .col-span-3(class="md:col-span-1")
      label.text-sm.text-gray-500 Операции
      table.w-full
        tbody(v-for="operation in operations")
          tr(v-for="item in operation.info")
            td {{ item.name }}
            td {{ item.count }}x&nbsp;{{ item.price | currency }}
          tr
            td Итого
            td {{ operation.sum }}
</template>

<script>
import { filter, sum } from "lodash";

const selected = { selected: true };

export default {
  props: {
    learner: { type: Object, required: true }
  },

  computed: {
    empty() {
      const count = sum(
        [this.sets, this.stamps, this.operations].map(l => l.length)
      );
      return count === 0;
    },

    sets() {
      return filter(this.learner.sets, selected);
    },

    stamps() {
      return filter(this.learner.stamps, selected);
    },

    operations() {
      return this.learner.operations;
    }
  }
};
</script>

<style scoped>
td:last-child {
  text-align: end;
}
</style>
