<template>
  <u-menu right>
    <template #activator="{toggle}">
      <u-button text variant="secondary" @click="toggle">
        {{ profile.surname }} {{ profile.name }}

        <div class="w-7 h-7 inline-flex rounded-full bg-purple-500 ml-2">
          <span class="m-auto text-sm font-medium text-white">
            {{ profile.surname[0] }}{{ profile.name[0] }}
          </span>
        </div>
      </u-button>
    </template>

    <u-card>
      <u-list>
        <p class="px-4 mt-2 mb-2 text-gray-400 text-xs font-medium uppercase">
          {{ profile.surname }} {{ profile.name }}
        </p>

        <logout-dialog />
      </u-list>
    </u-card>
  </u-menu>
</template>

<script>
import LogoutDialog from "@/app/shared/new-component/LogoutDialog";

export default {
  name: "UserProfile",
  components: { LogoutDialog },
  data() {
    return {
      profile: {
        surname: "Учитель",
        name: "Школы"
      }
    };
  },

  watch: {
    $route: {
      handler() {
        try {
          this.profile = JSON.parse(sessionStorage.getItem("profile"));
        } catch {
          //  pass
        }
      }
    }
  }
};
</script>
