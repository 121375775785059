<template lang="pug">
.flex.flex-col.justify-center.mr-4.pr-4.pl-4.relative(class="md:pl-12")
  .inline-flex.w-8.h-8.ml-1.bg-pink-400.rounded-full.absolute.left-0.items-center.justify-center.text-white.text-xs {{ profile.name[0] }}{{ profile.patronymic[0] }}
  p.hidden.text-xs.text-pink-600.font-medium(class="md:block") Здравствуйте!
  p.hidden.text-xs.text-pink-900.font-medium(class="md:block") {{ profile.name }} {{ profile.patronymic }}
</template>

<script>
export default {
  data() {
    return {
      profile: JSON.parse(sessionStorage.getItem("profile") || {}),
    };
  },
};
</script>