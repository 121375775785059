<template lang="pug">
header.header.bg-pink-200.pb-16(class="sm:pt-20 sm:pb-24")
  .bg-white.h-16.flex.items-center.shadow-sm.fixed.top-0.w-full.z-20
    CoreContainer.flex.w-full.justify-between
      CoreLogo
      .flex
        Profile
        ReloadButton.mr-4
        LogoutButton
  CoreContainer: CoreTitle(v-if="title") {{ title }}
</template>

<script>
export default {
  props: {
    title: String
  }
};
</script>

<style scoped>
.header {
  background: url("../assets/images/bg.jpg") #fbcfe8;
  background-size: cover;
}
</style>
