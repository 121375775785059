<template lang="pug">
span.mdi(:class="[icon, icon === 'mdi-loading' && 'animate-spin']")
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
  },
};
</script>