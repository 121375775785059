<template>
  <u-button @click="$router.push('/')" text variant="secondary">
    <u-icon left icon="mdi-arrow-left" />
    Вернуться назад
  </u-button>
</template>

<script>
export default {
  name: "BackButton"
};
</script>
