<template lang="pug">
.rounded.shadow-md.bg-white.pb-2.mb-8
  .flex.p-4(v-if="title || description")
    p.text-base {{ title }}
    p.text-sm.text-gray-500(v-if="description") {{ description }}
  .block: slot
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>