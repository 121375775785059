<template>
  <div class="current-version">Версия: <span>1.0.2</span></div>
</template>

<script>
export default {
  name: "CurrentVersion"
};
</script>

<style scoped>
.current-version {
  @apply fixed
    z-30
    bottom-2
    left-2
    text-xs
    font-medium
    rounded
    bg-white
    shadow-md
    text-gray-500
    p-2;
}

.current-version span {
  @apply text-pink-600 font-mono font-bold;
}
</style>
