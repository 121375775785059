import { sortBy } from "lodash";
import Api from "../services/Api";

export default {
  namespaced: true,

  state: {
    classes: [],
  },

  mutations: {
    ["SET_CLASSES"](state, classes) {
      state.classes = classes;
    },
  },

  actions: {
    async loadClasses({ commit }) {
      const { data } = await Api.classes.getClasses();
      commit("SET_CLASSES", sortBy(data, ["number", "liter"]));
    },
  },
};
