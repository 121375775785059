<template>
  <u-dialog persistent width="360" v-model="show">
    <template #activator="{open}">
      <u-list-item @click="open">
        <u-list-item-content>Выход</u-list-item-content>
      </u-list-item>
    </template>

    <u-card>
      <u-card-title>Выйти из системы?</u-card-title>

      <u-card-body v-if="error">
        <u-alert variant="danger">
          {{ error }}
        </u-alert>
      </u-card-body>

      <u-card-actions>
        <u-spacer />

        <u-button :disabled="loading" text variant="secondary" @click="close">
          Нет, остаться
        </u-button>

        <u-button
          :disabled="loading"
          :loading="loading"
          text
          variant="danger"
          @click="logout"
        >
          Да, выйти
        </u-button>
      </u-card-actions>
    </u-card>
  </u-dialog>
</template>

<script>
import Api from "@/app/services/Api";

export default {
  name: "LogoutDialog",

  data() {
    return {
      show: false,
      error: null,
      loading: false
    };
  },

  methods: {
    close() {
      this.show = false;
    },

    async logout() {
      try {
        this.loading = true;
        this.error = null;
        await Api.authorization.logout();
        sessionStorage.removeItem("profile");
        await this.$router.push({ name: "login" });
      } catch {
        this.error = "Ошибка выхода из системы, попробуйте позднее.";
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
