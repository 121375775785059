import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/classes",
    name: "home",
    component: Home,
    alias: "/",
    meta: "Главная"
  },
  {
    path: "/classes/:classId/request",
    name: "request",
    component: Home,
    meta: "Создание заявки"
  },
  {
    path: "/classes/:classId/archive",
    name: "archive",
    component: () =>
      import(/* webpackChunkName: "archive" */ "../views/Archive.vue"),
    meta: "Архив заявок"
  },
  {
    path: "/classes/:classId/recovery",
    name: "recovery",
    component: () =>
      import(/* webpackChunkName: "recovery" */ "../views/Recovery.vue"),
    meta: "Восстановление заявки"
  },
  {
    path: "/monitoring",
    name: "monitoring",
    meta: "Мониторинг",
    component: () =>
      import(/* webpackChunkName: "recovery" */ "../views/Monitoring.vue")
  },

  {
    path: "/monitoring/:classId",
    name: "class-monitoring",
    meta: "Мониторинг",
    component: () =>
      import(/* webpackChunkName: "recovery" */ "../views/ClassStats.vue")
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  }

  // // REDIRECTS
  // {
  //   path: "/",
  //   alias: "/classes"
  // },
];

const router = new VueRouter({
  routes
});

export default router;
