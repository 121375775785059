import Api from "../services/Api";

export default {
  namespaced: true,

  state: {
    menu: [],
    foodSets: [],
  },

  mutations: {
    ["SET_MENU"](state, menu) {
      state.menu = menu;
    },

    ["SET_FOODSETS"](state, foodSets) {
      state.foodSets = foodSets;
    },
  },

  actions: {
    async loadMenu({ commit }) {
      const { data: menu } = await Api.menu.getMenu();
      // const { data: foodSets } = await Api.menu.getFoodsets();

      commit("SET_MENU", menu);
      // commit("SET_FOODSETS", foodSets);
    },
  },
};
