<template lang="pug">
td.whitespace-nowrap.py-1(:class="isFirst ? 'p-1' : 'pl-4'", :colspan="colspan"): slot
</template>

<script>
export default {
  props: {
    isFirst: [Boolean, String],
    colspan: { type: [Number, String], default: 1 }
  }
};
</script>
