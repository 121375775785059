<template>
  <canvas class="w-full" id="qrcode"></canvas>
</template>

<script>
import qrcode from "qrcode";

export default {
  name: "QrCode",

  props: {
    value: {
      type: String
    }
  },

  watch: {
    value: {
      handler() {
        this.generateQrCode();
      }
    }
  },

  mounted() {
    this.generateQrCode();
  },

  methods: {
    generateQrCode() {
      const canvas = document.getElementById("qrcode");
      qrcode.toCanvas(canvas, this.value, { width: 180 });
    }
  }
};
</script>

<style lang="scss" scoped></style>
