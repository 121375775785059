<template>
  <div class="flex items-center justify-between">
    <back-button v-if="!isHomePage" />
    <u-spacer />

    <qr-code-auth v-if="!isExternalSite" class="mr-2" />
    <user-profile />
  </div>
</template>

<script>
import { isExternalSite } from "@/app/services/environment";
import BackButton from "@/app/shared/new-component/BackButton";
import QrCodeAuth from "@/app/shared/new-component/QrCodeAuth";
import UserProfile from "@/app/shared/new-component/UserProfile";

export default {
  name: "AppHeader",
  components: { QrCodeAuth, BackButton, UserProfile },

  computed: {
    isHomePage() {
      return this.$route.name === "home";
    },

    isExternalSite() {
      return isExternalSite();
    }
  }
};
</script>
