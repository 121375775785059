<template lang="pug">
tr.row.border-t.border-gray-300(
  @click="$emit('click')",
  :class="isLast ? 'bg-gray-100 text-xs' : 'text-sm'"
): slot
</template>

<script>
export default {
  props: {
    isLast: [Boolean, String]
  }
};
</script>

<style scoped>
.row:nth-child(2n) {
  @apply bg-gray-50;
}
</style>
