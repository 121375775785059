<template>
  <u-dialog width="320" persistent v-model="show">
    <template #activator="{open}">
      <u-button role="button" block @click="open">
        <u-icon left icon="mdi-qrcode" />
        Сканировать QR-код
      </u-button>
    </template>

    <u-card>
      <u-card-title>Сканирование QR-кода</u-card-title>

      <u-card-body>
        <p class="my-4 text-xs text-gray-400">
          Поднесите камеру вашего устройства к QR-коду
        </p>

        <u-alert v-if="isLoading">
          <span class="mr-2">🤨</span>
          Пожалуйста, разрешите доступ к камере
        </u-alert>

        <canvas class="w-full" id="qr-code-scanner" hidden></canvas>
      </u-card-body>

      <u-card-actions>
        <u-spacer />
        <u-button @click="show = false" text variant="danger">
          Отмена
        </u-button>
      </u-card-actions>
    </u-card>
  </u-dialog>
</template>

<script>
import jsQR from "jsqr";

export default {
  name: "QrCodeScanner",

  data() {
    return {
      show: false,
      video: null,
      canvasElement: null,
      canvas: null,
      isLoading: true
    };
  },

  watch: {
    show: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.video = document.createElement("video");
            this.canvasElement = document.getElementById("qr-code-scanner");
            this.canvas = this.canvasElement.getContext("2d");

            this.$nextTick(() => this.start());
          });
        }
      }
    }
  },

  methods: {
    drawLine(begin, end, color) {
      this.canvas.beginPath();
      this.canvas.moveTo(begin.x, begin.y);
      this.canvas.lineTo(end.x, end.y);
      this.canvas.lineWidth = 4;
      this.canvas.strokeStyle = color;
      this.canvas.stroke();
    },

    tick() {
      if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        this.isLoading = false;
        this.canvasElement.hidden = false;

        this.canvasElement.height = this.video.videoHeight;
        this.canvasElement.width = this.video.videoWidth;

        this.canvas.drawImage(
          this.video,
          0,
          0,
          this.canvasElement.width,
          this.canvasElement.height
        );

        const imageData = this.canvas.getImageData(
          0,
          0,
          this.canvasElement.width,
          this.canvasElement.height
        );

        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert"
        });

        if (code) {
          this.drawLine(
            code.location.topLeftCorner,
            code.location.topRightCorner,
            "#8B5CF6"
          );

          this.drawLine(
            code.location.topRightCorner,
            code.location.bottomRightCorner,
            "#8B5CF6"
          );

          this.drawLine(
            code.location.bottomRightCorner,
            code.location.bottomLeftCorner,
            "#8B5CF6"
          );

          this.drawLine(
            code.location.bottomLeftCorner,
            code.location.topLeftCorner,
            "#8B5CF6"
          );

          this.sendQrCodeData(code.data);
        }
      }

      requestAnimationFrame(this.tick);
    },

    sendQrCodeData(qrcode) {
      if (!this.show) return;

      this.$emit("qrcode", qrcode);
      this.show = false;
    },

    start() {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then(stream => {
          this.video.srcObject = stream;
          this.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          this.video.play();
          requestAnimationFrame(this.tick);
        });
    }
  }
};
</script>
