import {
  cloneDeep,
  compact,
  find,
  isEmpty,
  isUndefined,
  map,
  sum,
} from "lodash";
import Vue from "vue";

export default {
  namespaced: true,

  state: {
    model: {},
    customSet: [],
  },

  mutations: {
    ["SET_EMPTY_POSITION"](state, learnerId) {
      Vue.set(state.model, learnerId, { type: "empty", value: null });
    },

    ["SET_FOOD_SET"](state, { learnerId, foodSetId }) {
      Vue.set(state.model, learnerId, state.model[learnerId] || {});

      if (state.model[learnerId].type !== "set") {
        Vue.set(state.model[learnerId], "type", "set");
        Vue.set(state.model[learnerId], "value", []);
      }

      const { value } = state.model[learnerId];

      if (value.includes(foodSetId)) {
        value.splice(value.indexOf(foodSetId), 1);
      } else {
        value.push(foodSetId);
      }
    },

    ["ADD_IN_SET"](state, { food, type }) {
      let foodInSet = find(state.customSet, {
        name: food.name,
        price: food.price,
      });

      if (isUndefined(foodInSet)) {
        foodInSet = cloneDeep(food);
        foodInSet.count = 0;
        foodInSet.type = type;

        state.customSet.push(foodInSet);
      }

      foodInSet.count += 1;
    },

    ["REMOVE_FROM_SET"](state, index) {
      state.customSet.splice(index, 1);
    },

    ["ADD_SET_TO_REQUEST"](state, learnerId) {
      Vue.set(state.model, learnerId, {
        type: "custom",
        value: state.customSet,
      });
      state.customSet = [];
    },

    ["DEFAULT_SET"](state, originalSet) {
      state.customSet = originalSet;
    },

    ["CLEAR_SET"](state) {
      state.customSet = [];
    },
  },

  actions: {
    toggleFoodSet({ commit }, { learnerId, foodSetId }) {
      commit("SET_FOOD_SET", { learnerId, foodSetId });
    },

    toggleDisable({ commit }, learnerId) {
      commit("SET_EMPTY_POSITION", learnerId);
    },

    addInSet({ commit }, { food, type }) {
      commit("ADD_IN_SET", { food, type });
    },

    removeFromSet({ commit }, index) {
      commit("REMOVE_FROM_SET", index);
    },

    addSetToRequest({ commit }, learnerId) {
      commit("ADD_SET_TO_REQUEST", learnerId);
    },

    defaultSet({ commit }, originalSet) {
      commit("DEFAULT_SET", originalSet);
    },

    clearSet({ commit }) {
      commit("CLEAR_SET");
    },
  },

  getters: {
    isPositionCustom: (state) => (learnerId) => {
      return state.model[learnerId] && state.model[learnerId].type === "custom";
    },

    customSetCount: (state) => {
      return sum(
        map(state.model, (pos) => {
          if (pos && pos.type === "custom") {
            return 1;
          }
        })
      );
    },

    foodSetCount: (state) => (foodSetId) => {
      return sum(
        map(state.model, (pos) => {
          if (pos && pos.type === "set" && pos.value.includes(foodSetId)) {
            return 1;
          } else {
            return 0;
          }
        })
      );
    },

    hasFoodSet: (state) => (learnerId, foodSetId) => {
      const pos = state.model[learnerId];
      return pos && pos.value ? pos.value.includes(foodSetId) : false;
    },

    isDisabled: (state) => (learnerId) => {
      return (
        isUndefined(state.model[learnerId]) ||
        isEmpty(state.model[learnerId].value)
      );
    },

    positions: (state) => {
      return compact(
        map(state.model, (value, learnerId) => {
          if (!value || value.locked || isEmpty(value.value)) {
            return null;
          }

          const pos = {
            userId: parseInt(learnerId),
          };

          if (value.type === "set") {
            pos.foodSetId = Array.from(value.value);
          } else {
            pos.operation = {
              info: value.value,
            };
          }

          return pos;
        })
      );
    },
  },
};
