var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.classes.length > 3)?_c('u-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toggle = ref.toggle;
return [_c('u-button',{staticClass:"w-36",on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.selected || "Выберите класс")+" ")])]}}],null,false,343456884)},[_c('u-card',[_c('u-card-body',_vm._l((_vm.classes),function(cl){return _c('router-link',{key:cl.id,attrs:{"custom":"","to":{ name: 'request', params: { classId: cl.id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('u-tab',{staticClass:"w-full my-1",attrs:{"active":isActive},on:{"click":function($event){_vm.$router.push({ name: 'request', params: { classId: cl.id } }),
                (_vm.selected = "" + (cl.number) + (cl.liter) + " класс")}}},[_vm._v(" "+_vm._s(cl.number)+_vm._s(cl.liter)+" класс ")])]}}],null,true)})}),1)],1)],1):_c('div',{staticClass:"flex overflow-x-auto mr-4 flex-1"},_vm._l((_vm.classes),function(cl){return _c('router-link',{key:cl.id,attrs:{"custom":"","to":{ name: 'request', params: { classId: cl.id } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var navigate = ref.navigate;
                var isActive = ref.isActive;
return [_c('u-tab',{attrs:{"active":isActive},on:{"click":navigate}},[_vm._v(" "+_vm._s(cl.number)+_vm._s(cl.liter)+" ")])]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }