<template>
  <!--CoreButton(type="danger", @click="goToRecovery")-->
  <!--  Icon(class="sm:mr-2", icon="mdi-cached") -->
  <!--  span.hidden(class="sm:inline-block") Заявка восстановления-->

  <div class="inline">
    <u-hide-at breakpoint="md">
      <u-button variant="danger" @click="goToRecovery" icon>
        <u-icon icon="mdi-cached" />
      </u-button>
    </u-hide-at>

    <u-show-at breakpoint="md">
      <u-button variant="danger" text @click="goToRecovery">
        Заявка восстановления
      </u-button>
    </u-show-at>
  </div>
</template>

<script>
export default {
  methods: {
    goToRecovery() {
      const { classId } = this.$route.params;
      this.$router.push({ name: "recovery", params: { classId } });
    }
  }
};
</script>
