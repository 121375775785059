import Vue from "vue";
import { camelCase, forEach, last, replace, split, upperFirst } from "lodash";

const register = data =>
  forEach(data, ctx =>
    forEach(ctx.keys(), path => {
      const normPath = replace(last(split(path, "/")), /\.\w+$/, "");
      const component = ctx(path);
      const name = upperFirst(camelCase(normPath));

      Vue.component(name, component.default || component);
    })
  );

// Need use this because the path must be a literal regex!
const coreComponents = require.context("@/app/shared", true, /[A-Z]\w+\.vue$/);

register([coreComponents]);