import { render, staticRenderFns } from "./TheHeaderAlt.vue?vue&type=template&id=430faace&scoped=true&lang=pug&"
import script from "./TheHeaderAlt.vue?vue&type=script&lang=js&"
export * from "./TheHeaderAlt.vue?vue&type=script&lang=js&"
import style0 from "./TheHeaderAlt.vue?vue&type=style&index=0&id=430faace&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430faace",
  null
  
)

export default component.exports