import Vue from "vue";
import Vuex from "vuex";

import classes from "./classes.module";
import menu from "./menu.module";
import request from "./request.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    classes,
    menu,
    request,
  },
});
