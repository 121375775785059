<template lang="pug">
CoreButton(@click="reload")
  Icon(class="md:mr-2", icon="mdi-refresh") 
  span.hidden(class="md:inline-block") Обновить
</template>

<script>
export default {
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>